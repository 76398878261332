
$green: #e6c33c;
$red: #B63F52;
$slate: #222;
$black: #000;
$flurogreen: #e6c33c;
$blue: #153CB4;
$orange: #F9AC53;
$pink: #F62E97;
$peach: #E93479;
$purple: #94167F;
$indigo: #300350;

$border-radius: 0.1rem;
$theme-colors: ( "dark": #073642, "primary": #444, "secondary": #375a7f, "warning": $red, "success": $green ); 
$navbar-dark-brand-color: $green;

@import 'bootstrap-custom.scss';


body {
  margin: 0;
  padding: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0 6px $green;

}

// body:before {
//     content: " ";
//     display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     background: linear-gradient(
//         to bottom,
//         rgba(18, 16, 16, 0) 50%,
//         rgba(0, 0, 0, 0.25) 50%
//     );
//     background-size: 100% 8px;
//     z-index: 2;
//     pointer-events: none;
// }



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// GENERAL ~~~~~~~~~~~~~~~~~~~~~~~~~
.App {
    height: 100vh;
    background-color: $black;
    background-image: radial-gradient(
        rgba(0, 0, 0, 0.75), black 120%
    );
    display: flex;
    flex-direction: column;
}

.app-body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.h3, .h4, .h5 {
  color: $peach;
  font-weight: 300;
}

.form-control {
    border-radius: $border-radius;
}

.buttons {
    height: 0;
    width: 100%;
    position: relative;

    .button-left, button-right {
        margin-top: 0.35rem;
    }
}

.btn {
    border-radius: initial;
    background-color: transparent;
    border-color: transparent;
    &:hover {
        background-color: $green;
    }
}

.badge {
    font-weight: 500;
}

.task-button {
    width: 100%;
}

.date-text {
    margin: 10px;
    color: $green;
}

.notes {
    flex-grow: 1;
}

.notes textarea, .notes textarea:focus {
    resize: none;
    height: 100%;
    background-color: transparent;
    caret-color: $flurogreen;
    color: $flurogreen;
    border: 1px solid #242112;
    box-shadow: none;
    text-shadow: 0 0 6px $green;
}

.icon {
   // background-image: url('icon-square.png');
    width: 32px;
    height: 29px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0.2rem;
    margin-top: 0.2rem;
}

.search-result {
    cursor: pointer;
    &:hover {
      background-color: #d4d4d4;
      i {
        text-decoration: underline;
      }
    }
}

.today-marker {
    margin-bottom: -20px;
}

.login-button {
    width: 100%;
}

// NAV ~~~~~~~~~~~~~~~~~~~~~~~~~
.navbar-brand {
    margin-right: 0.5rem;
}

.navbar {
    padding-left: 30px;
    .react-calendar {
        z-index: 3000;
    }
}

/* Note - lots of minging !importants used below. I could switch to the styleless version by using:
    import Calendar from 'react-calendar/dist/entry.nostyle 
    Maybe some day.
*/
.react-calendar {
    position: absolute;
    right: 30px;
    z-index: 1000;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    font-family: inherit !important;
    border-radius: $border-radius !important;

    abbr {
        text-decoration: none;
        cursor: default;
    }

    .react-calendar__tile--now {
        -webkit-box-shadow: inset 0px 0px 0px 2px $green;
        -moz-box-shadow: inset 0px 0px 0px 2px $green;
        box-shadow: inset 0px 0px 0px 2px $green;
        border-radius: $border-radius !important;
    }

    .react-calendar button {
        border-radius: $border-radius !important;
    }
    .react-calendar__tile--active {
        background: $slate !important;
    }
}

.signout-button, .calendar-button, .user-button, .export-button, .bookmark-button {
    svg {
        height: 30px;
        width: 30px;
    }
    filter: drop-shadow(2px 2px 10px $green);
}

.nav-item {
    button, a {
        &:hover, &.menu-open {
            svg path {
                fill: $green;
            }

            .mob-link {
                color: $green;
            }
        }

        svg path {
            transition: fill 0.3s ease;
            fill: $green;
        }
    }

    .mob-link {
        color: $green;
        margin-left: 1rem;
    }

    .dropdown-menu {
        border-radius: $border-radius;
    }
}

.nav-search {
    display: flex;
    filter: drop-shadow(2px 2px 10px $green);

    form {
        position: relative; // so that child clear icon can be absolute, but relative to this :(
    }

    .populated+.search-clear {
        display: inline-flex;
        &:hover {
            cursor: pointer;
            stroke: $red;
        }
        
    }
    .search-clear {
        height: 13px;
        width: 13px;
        position: absolute;
        right: 8px;
        display: none;
        stroke: $slate;
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .form-inline .form-control {
        opacity: 0;
        transition: all 0.5s;
        padding: 0;
        border: none;
        width: 300px;
        max-width: 0px;
        &.populated {
            opacity: 1;
            padding: 0.375rem 0.75rem;
            border: 1px;
            max-width: 400px;
        }
    }

    &:hover, &:focus, &:active {
        .form-inline .form-control {
            opacity: 1;
            padding: 0.375rem 0.75rem;
            border: 1px;
            max-width: 400px;
        }
    }
}

.user-button, .search-button {
    svg {
        height: 30px;
        width: 34px;
    }
}

.dropdown-menu {
    left: auto;
    right: 30px;
    top: auto;
    .dropdown-item {
        svg {
            width: 26px;
            height: 26px;
            fill: $red;
        }
    }
}

.navbar-collapse.show {
    position: absolute;
    top: 53px;
    background-color: #222222f2;
    left: 0;
    width: 100%;
    z-index: 5000;

    .nav-search {
        display: flex;

        .form-inline {
            width: calc(100% - 90px);

            .form-control {
                width: 100%;
                opacity: 1;
                padding: 0.375rem 0.75rem;
                border: 1px;
                max-width: 500px;
            }
        }
    }
}

// END nav ---

// HOME ~~~~~~~~~~~~~~~~~~~~~~~~~

.demo-gif {
    img {
        max-width: 100%;
        box-shadow: 0px 0px 5px grey;
    }
}

// END home

// TASK LIST ~~~~~~~~~~~~~~~~~~~~~~~~~

.task-list-task {
    padding: 0.2em 0.75em;
    line-height: 1.8rem;

    .btn-sm {
        line-height: 1.3em;
    }
}

.list-group-item:first-child {
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0.1rem;
    border-bottom-left-radius: 0.1rem;
}

// END task list ---

// EXPORT PAGE ~~~~~~~~~~~~~~~~~~~~~

.export-page {
    .react-calendar {
        position: initial;
    }
}

// END export page

// FORMS ~~~~~~~~~~~~~~~~~~~~~~~~~

/* TODO - media query and remove button padding for tiny devices (iphone 5 etc)
    .login-form {
    .btn-register, .btn-forgot {
        width: 100%;
    }
}*/

// END forms